import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Image from "gatsby-image"

export default ({ data, location }) => {
  const {
    site: { siteMetadata },
  } = data
  const post = data.mdx

  const siteTitle = siteMetadata?.title || `Title`
  const author = data.site.siteMetadata?.author

  const avatar = data?.avatar?.childImageSharp?.fixed
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title="About me"
        description="A page about my background, work, and interests."
      />
      <div className="flex justify-center">
        {avatar && (
          <Image
            fixed={avatar}
            alt={author?.name || ``}
            className="bio-avatar"
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
        )}
      </div>
      <MDXRenderer className="text-xl">{post.body}</MDXRenderer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BioPageData {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
      childImageSharp {
        fixed(width: 300, height: 300, quality: 95) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    mdx(fileAbsolutePath: { regex: "/bio/" }) {
      id
      body
      frontmatter {
        date
        description
        title
      }
    }
  }
`
